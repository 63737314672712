import React from 'react';

export default abstract class Page<P = {}, S = {}, SS = any> extends React.Component<P, S, SS>
{
    abstract name: string;

    abstract pageRender(): React.ReactNode;

    render()
    {
        return (
            <main className={"App-main "+ this.name}>
                {this.pageRender()}
            </main>
        )
    }
}