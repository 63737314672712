import React from 'react';
import { history, OrderNew, getSessID } from '../Utils';
import $ from "jquery";

import "./OrderNewItem.scss";

import {Popup} from 'reactjs-popup';

interface IOrderNewItemProps
{
    data: OrderNew;
    onDelete: (data: OrderNew) => void;
}

interface IOrderNewItemState
{
    popupOpen: boolean
}

export default class OrderNewItem extends React.Component<IOrderNewItemProps, IOrderNewItemState>
{
    constructor(props: IOrderNewItemProps)
    {
        super(props);

        this.editOrder = this.editOrder.bind(this);
        this.printOrder = this.printOrder.bind(this);
        this.delOrder = this.delOrder.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.popupDel = this.popupDel.bind(this);

        this.state = {
            popupOpen: false
        }
    }

    delOrder()
    {
        this.setState({
            popupOpen: true
        })
    }

    editOrder()
    {
        history.push("/order/1/"+this.props.data.id)
    }

    printOrder()
    {
        // window.open("/print/"+this.props.data.id);
        window.open("/print/"+this.props.data.id, "Drukowanie","menubar=no,resizable=no,scrollbars=no,status=no,location=no");

    }

    closePopup()
    {
        this.setState({popupOpen: false});
    }

    popupDel()
    {
        this.props.onDelete(this.props.data);

        $.post("/api/order_remove", {
            sessid: getSessID(),
            id: this.props.data.id
        })

        this.closePopup();
    }

    render()
    {
        return (
            <div className="OrderNewItem">
                <div>{this.props.data.nr}</div>
                <div>{this.props.data.fullname}</div>
                <div>{this.props.data.item}</div>
                <div className="OrderNewItem-actions">
                    <div onClick={this.editOrder}>Edytuj</div>
                    <div onClick={this.printOrder}>Drukuj</div>
                    <div onClick={this.delOrder}>Usuń</div>
                </div>
                <Popup open={this.state.popupOpen} onClose={this.closePopup} >
                    <div className="title">Usunąć zlecenie?</div>
                    <div className="msg">
                        Na pewno usunąć to zlecenie?<br/><br/>
                        {this.props.data.nr}<br/>
                        {this.props.data.fullname}<br/>
                        {this.props.data.item}<br/>
                    </div>
                    <div className="actions">
                        <div className="action" onClick={this.popupDel}  >Usuń</div>
                        <div className="action" onClick={this.closePopup} >Zamknij</div>
                    </div>
                </Popup>
            </div>
        )
    }
}