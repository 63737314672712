import React from 'react';
import UIPage from "./UIPage";

import "./ErrorPage.scss"

export default class ErrorPage extends UIPage
{
    name = "Error";

    pageRender()
    {
        return (
            <React.Fragment>
                <b>Strona nie istnieje, lub brak dostępu.</b>
            </React.Fragment>
        )
    }
}