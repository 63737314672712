import React from 'react';

import "./Header.scss"

class Header extends React.Component
{
    render()
    {
        return (
            <header className="App-header">
                STIHL<sup>&reg;</sup>
            </header>
        )
    }
}

export default Header;
