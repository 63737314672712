import React from 'react';

import UIPage from "./UIPage";
import { RouteComponentProps } from 'react-router-dom';

import "./OrderPage.scss"
import { OrderNew, getSessID, history } from '../Utils';

import $ from "jquery";
import { Popup } from 'reactjs-popup';

export enum OrderPageMode
{
    ADD = 0,
    EDIT = 1
}

enum OrderPageAction
{
    EMPTY = -1,
    ADD = 0,
    EDIT = 1,
    COMPLETE = 2
}


async function getOrder(id: number): Promise<OrderNew>
{
    let d = await $.post("/api/orderOne", {id: id, sessid: getSessID()});
  
    let data = JSON.parse(d);   
    return data.data;
}

interface IOrderPageState
{
    order?: OrderNew;
    msg?: string;
    popupOpen: boolean;
    popupTitle?: string;
    popupMsg?: React.ReactFragment;
    popupActions?: React.ReactFragment;
}

export default class OrderPage extends UIPage<RouteComponentProps<{mode: any, order?: any}>, IOrderPageState>
{
    name = "Order";

    action: OrderPageAction = OrderPageAction.EMPTY;

    data: {[x: string]: any;};

    constructor(props: any)
    {
        super(props);

        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.print = this.print.bind(this);
        this.printId = this.printId.bind(this);
        this.sumbit = this.sumbit.bind(this);
        this.complete = this.complete.bind(this);

        this.closePopup = this.closePopup.bind(this);
        this.openPopup = this.openPopup.bind(this);

        this.popupAdd = this.popupAdd.bind(this);
        this.popupEdit = this.popupEdit.bind(this);
        this.popupComplete = this.popupComplete.bind(this);

        this.onChangeArea = this.onChangeArea.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);

        this.state = {
            popupOpen: false
        }
        this.data = {};
    }

    componentDidMount()
    {
        let mode: OrderPageMode = OrderPageMode.ADD;

        if (this.props.match.params.mode)
        {
            mode = Number.parseInt(this.props.match.params.mode);
        }

        if (mode === OrderPageMode.EDIT)
        {
            if (this.props.match.params.order)
            {
                getOrder(this.props.match.params.order).then(e => {
                    
                    this.setState({
                        order: e
                    })
                });
            }
        }
    }

    complete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    {
        this.action = OrderPageAction.COMPLETE;
    }

    edit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    {
        this.action = OrderPageAction.EDIT;
    }

    add(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    {
        this.action = OrderPageAction.ADD;
    }

    print(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    {
        this.printId(this.props.match.params.order);
    }

    printId(id: any = this.props.match.params.order, simple: boolean = false)
    {
        window.open("/print/"+id+(simple? "/0" :""), "Drukowanie","menubar=no,resizable=no,scrollbars=no,status=no,location=no");
    }

    popupAdd()
    {
        let tmp = {
            sessid: getSessID()
        };

        Object.assign(tmp, this.data)     

        $.post("/api/order_add", tmp).then(e => {
            e = JSON.parse(e);
            
            console.log(e);
            

            if (e.status === "success")
            {
                this.setState({
                    popupTitle: "Dodano zlecenie",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={()=>this.printId(e.id, true)} >Drukuj</div>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                });

                this.clear();
            }
            else
            {
                this.setState({
                    popupTitle: "Wystąpił błąd",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                })
            }
        });
    }

    popupEdit()
    {
        let tmp = {
            sessid: getSessID(),
            id: this.state.order?.id
        };


        Object.assign(tmp, this.data)     

        $.post("/api/order_edit", tmp).then(e => {
            e = JSON.parse(e);          

            if (e.status === "success")
            {
                this.setState({
                    popupTitle: "Zlecenie zmienione",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={()=>this.printId()} >Drukuj</div>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                });
                
                // this.clear();
            }
            else
            {
                this.setState({
                    popupTitle: "Wystąpił błąd",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                })
            }
        });
    }

    popupComplete()
    {
        let tmp = {
            sessid: getSessID(),
            status: 1,
            id: this.state.order?.id
        };

        Object.assign(tmp, this.data)

        $.post("/api/order_complete", tmp).then(e => {
            console.log(e);
            e = JSON.parse(e);       
            
            console.log(e);
            

            if (e.status === "success")
            {
                this.setState({
                    popupTitle: "Zlecenie zakończone",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}<br/>
                            Po kliknięciu 'Zamknij' zostaniesz przeniesiony do niewykonanych.
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={()=>this.printId()} >Drukuj</div>
                            <div className="action" onClick={()=>history.push("/search/status=0")} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                });
                
                // this.clear();
            }
            else
            {
                this.setState({
                    popupTitle: "Wystąpił błąd",
                    popupMsg: (
                        <React.Fragment>
                            {e.msg}
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    ),
                    msg: e.msg
                })
            }
        });
    }

    sumbit(event: React.FormEvent<HTMLFormElement>)
    {
        event.stopPropagation();
        event.preventDefault();

        switch (this.action) {
            case OrderPageAction.ADD:
                this.setState({
                    popupTitle: "Dodać zlecenie?",
                    popupMsg: (
                        <React.Fragment>
                            Sprawdź wprowadzone dane
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.popupAdd} >Dodaj</div>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    )
                })
                break;
            case OrderPageAction.EDIT:
                this.setState({
                    popupTitle: "Edytować zlecenie?",
                    popupMsg: (
                        <React.Fragment>
                            Sprawdź wprowadzone dane
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.popupEdit} >Edytuj</div>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    )
                })
                break;
            case OrderPageAction.COMPLETE:
                this.setState({
                    popupTitle: "Zakończyć zlecenie?",
                    popupMsg: (
                        <React.Fragment>
                            Sprawdź wprowadzone dane
                        </React.Fragment>
                    ),
                    popupActions: (
                        <React.Fragment>
                            <div className="action" onClick={this.popupComplete} >Zakończ</div>
                            <div className="action" onClick={this.closePopup} >Zamknij</div>
                        </React.Fragment>
                    )
                })
                break;
        
            default:
                break;
        }

        this.openPopup();
    }

    onChangeInput(event: React.ChangeEvent<HTMLInputElement>)
    {
        this.data[event.target.id] = event.target.value;
    }

    onChangeArea(event: React.ChangeEvent<HTMLTextAreaElement>)
    {
        this.data[event.target.id] = event.target.value;
    }

    pageRender()
    {
        let mode: OrderPageMode = OrderPageMode.ADD;

        if (this.props.match.params.mode)
        {
            mode = Number.parseInt(this.props.match.params.mode);
        }

        switch (mode)
        {
            case OrderPageMode.ADD:
                return this.renderAdd();
            case OrderPageMode.EDIT:
                if (this.props.match.params.order)
                    return this.renderEdit();
                else
                    return this.renderError();
            default:
                return this.renderError();
        }
    }

    clear()
    {
        $(".form input:not(disabled)").val("");
        $(".form textarea:not(disabled)").val("");
    }

    renderError()
    {
        return (
            <React.Fragment>
                <h2>
                    Błąd
                </h2>
                <p>
                    {
                        this.state.msg
                    }
                </p>
            </React.Fragment>
        )
    }

    closePopup()
    {
        this.setState({
            popupOpen: false
        })
    }

    openPopup()
    {
        this.setState({
            popupOpen: true
        })
    }

    renderAdd()
    {
        return (
            <React.Fragment>
                <h2>
                    Dodaj zlecenie
                </h2>
                <p>
                    {
                        this.state.msg
                    }
                </p>
                <form className="form" onSubmit={this.sumbit} >
                    <div>
                        <label htmlFor="fullname">Imię Nazwisko / Firma</label>
                        <input type="text" name="fullname" id="fullname" autoComplete="off" onChange={this.onChangeInput} placeholder="Jan Kowalski" required />
                    </div>
                    <div>
                        <label htmlFor="address">Adres</label>
                        <input type="text" name="address" id="address" autoComplete="off" onChange={this.onChangeInput} placeholder="ul. Ogrodowa 6a/8" />
                    </div>
                    <div>
                        <label htmlFor="post">Poczta</label>
                        <input type="text" name="post" id="post" autoComplete="off" onChange={this.onChangeInput}  placeholder="00-000 Miasto"  />
                    </div>
                    <div>
                        <label htmlFor="tel">Telefon</label>
                        <input type="text" name="tel" id="tel" autoComplete="off" onChange={this.onChangeInput} required />
                    </div>
                    <div>
                        <label htmlFor="item">Towar</label>
                        <input type="text" name="item" id="item" autoComplete="off" onChange={this.onChangeInput} required />
                    </div>
                    <div>
                        <label htmlFor="serial">Nr Seryjny</label>
                        <input type="text" name="serial" id="serial" autoComplete="off" onChange={this.onChangeInput} />
                    </div>
                    <div>
                        <label htmlFor="remarks">Treść zlecenia</label>
                        <textarea name="remarks" id="remarks" cols={40} rows={15} autoComplete="off" onChange={this.onChangeArea} required />
                    </div>
                    <div className="action">
                        <button type="submit" onClick={this.add}>Dodaj</button>
                        {/* <button type="button" onClick={this.print}>Drukuj</button> */}
                    </div>
                </form>
                <Popup open={this.state.popupOpen} onClose={this.closePopup} >
                    <div className="title">{this.state.popupTitle}</div>
                    <div className="msg">{this.state.popupMsg}</div>
                    <div className="actions">
                        {this.state.popupActions}
                    </div>
                </Popup>
            </React.Fragment>
        )
    }

    renderEdit()
    {
        return (
            <React.Fragment>
                <h2>
                    Edytuj zlecenie
                </h2>
                <p>
                    {
                        this.state.msg
                    }
                </p>
                <form className="form" onSubmit={this.sumbit} >
                    <div>
                        <label htmlFor="fullname">Imię Nazwisko / Firma</label>
                        <input type="text" name="fullname" id="fullname" autoComplete="off" placeholder="Jan Kowalski" defaultValue={this.state.order?.fullname} disabled />
                    </div>
                    <div>
                        <label htmlFor="address">Adres</label>
                        <input type="text" name="address" id="address" autoComplete="off" placeholder="ul. Ogrodowa 6a/8" defaultValue={this.state.order?.address} disabled />
                    </div>
                    <div>
                        <label htmlFor="post">Poczta</label>
                        <input type="text" name="post" id="post" autoComplete="off"  placeholder="00-000 Miasto" defaultValue={this.state.order?.post} disabled  />
                    </div>
                    <div>
                        <label htmlFor="tel">Telefon</label>
                        <input type="text" name="tel" id="tel" autoComplete="off" defaultValue={this.state.order?.tel} disabled />
                    </div>
                    <div>
                        <label htmlFor="item">Towar</label>
                        <input type="text" name="item" id="item" autoComplete="off" defaultValue={this.state.order?.item} disabled />
                    </div>
                    <div>
                        <label htmlFor="serial">Nr Seryjny</label>
                        <input type="text" name="serial" id="serial" autoComplete="off" defaultValue={this.state.order?.serial} disabled />
                    </div>
                    <div>
                        <label htmlFor="remarks">Treść zlecenia</label>
                        <textarea name="remarks" id="remarks" cols={40} rows={10} autoComplete="off" defaultValue={this.state.order?.remarks} disabled />
                    </div>
                    <div>
                        <label htmlFor="materials">Materiały</label>
                        <textarea name="materials" id="materials" cols={40} rows={9} autoComplete="off" defaultValue={this.state.order?.materials} onChange={this.onChangeArea} />
                    </div>
                    <div>
                        <label htmlFor="labor">Naprawa</label>
                        <textarea name="labor" id="labor" cols={40} rows={9} autoComplete="off" defaultValue={this.state.order?.labor} onChange={this.onChangeArea} />
                    </div>
                    <div>
                        <label htmlFor="notes">Uwagi</label>
                        <textarea name="notes" id="notes" cols={40} rows={9} autoComplete="off" defaultValue={this.state.order?.notes} onChange={this.onChangeArea} />
                    </div>
                    <div>
                        <label htmlFor="cost">Koszt razem (bez zł)</label>
                        <input type="number" step={0.01}  name="cost" id="cost" autoComplete="off" defaultValue={this.state.order?.cost} onChange={this.onChangeInput} required />
                    </div>
                    <div>
                        <label htmlFor="serviceman">Serwisant</label>
                        <input type="text" name="serviceman" id="serviceman" autoComplete="off" defaultValue={this.state.order?.serviceman} onChange={this.onChangeInput} required />
                    </div>
                    <div className="action">
                        <button type="submit" onClick={this.edit}>Edytuj</button>
                        <button type="submit" onClick={this.complete}>Zakończ</button>
                        <button type="button" onClick={this.print}>Drukuj bez zmian</button>
                    </div>
                </form>
                <Popup closeOnEscape={false} closeOnDocumentClick={false} open={this.state.popupOpen} onClose={this.closePopup} >
                    <div className="title">{this.state.popupTitle}</div>
                    <div className="msg">{this.state.popupMsg}</div>
                    <div className="actions">
                        {this.state.popupActions}
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}