import React, { createRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import $ from "jquery";

import UIPage from "./UIPage";
import { OrderNew, getSessID } from '../Utils';
import OrderNewItem from '../components/OrderNewItem';

import "./SearchPage.scss"

async function search(props: any)
{
    let d = await $.post("/api/order", props);
    let data = JSON.parse(d);
    return data;
}

function convert(query: string)
{
    let tmp: { [x: string]: any; } = {};

    let arr = query.split(",");

    arr.forEach(el => {
        let t = el.split("=")

        if (t.length === 2)
            tmp[t[0]] = t[1];
    })

    return tmp;
}

interface ISearchPageState
{
    items: OrderNew[];
    size: number;
    curr: number;
    msg: string;
}

export default class SearchPage extends UIPage<RouteComponentProps<{query?: string}>, ISearchPageState>
{
    name = "Search";
    itemsPerPage = 10;

    inputNrRef = createRef<HTMLInputElement>();
    inputNameRef = createRef<HTMLInputElement>();

    constructor(props: any)
    {
        super(props);

        this.state = {
            items: [],
            size: 0,
            curr: 0,
            msg: ""
        }

        

        this.search = this.search.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.left = this.left.bind(this);
        this.right = this.right.bind(this);
        this.select = this.select.bind(this);
    }

    search(data: any = {})
    {
        data["sessid"]= getSessID();

        data["limit"] = this.itemsPerPage;

        if (this.state.curr > 0)
        {
            data["limit_offset"] = this.state.curr * this.itemsPerPage;
        }


        if (this.inputNrRef.current && this.inputNrRef.current.value.length > 0)
        {
            data["nr"] = "%" + this.inputNrRef.current.value + "%";
        }

        if (this.inputNameRef.current && this.inputNameRef.current.value.length > 0)
        {
            data["fullname"] = "%" + this.inputNameRef.current.value + "%";
        }

        if (this.props.match.params.query)
        {
            Object.assign(data, convert(this.props.match.params.query));
        }


        search(data).then(e => {

            if (e.status === "success")
                this.setState({
                    size: e.data.size,
                    items: e.data.items
                })
            else
                this.setState({
                    msg: e.msg
                })
        })
    }

    deleteItem(data: OrderNew)
    {
        let arr = this.state.items.filter(e => {
            if (e.id === data.id) return false;
            else return true;
        })

        this.setState({
            items: arr
        })

        setTimeout(this.search, 1000);
    }

    componentDidMount()
    {
        if (this.props.match.params.query)
        {
            let tmp = {
                sessid: getSessID()
            };

            Object.assign(tmp, convert(this.props.match.params.query));

            this.search(tmp);
        }
        else
        {
            let tmp = {
                sessid: getSessID()
            };

            this.search(tmp);
        }
    }

    componentDidUpdate(props: RouteComponentProps<{query?: string}>, state: ISearchPageState)
    {
        if (props.match.params.query !== this.props.match.params.query)
        {
            if (this.props.match.params.query)
            {
                let tmp = {
                    sessid: getSessID()
                };
    
                Object.assign(tmp, convert(this.props.match.params.query));
    
                this.search(tmp);
            }
            else if (this.props.match.params.query === undefined)
            {
                let tmp = {
                    sessid: getSessID()
                };

                this.search(tmp);
            }
        }

        if (this.state.curr !== state.curr)
        {
            this.search();
        }
        
    }

    searchClick()
    {
        this.search();
    }
    

    left(event: React.MouseEvent<HTMLDivElement, MouseEvent>)
    {
        this.setState({
            curr: Math.max(0, this.state.curr-1)
        })
    }

    right(event: React.MouseEvent<HTMLDivElement, MouseEvent>)
    {
        this.setState({
            curr: Math.min(this.state.size/this.itemsPerPage, this.state.curr+1)
        })
    }

    select(event: React.MouseEvent<HTMLDivElement & {data: number}, MouseEvent>)
    {
        let el = event.target;
        this.setState({
            curr: $(el).data("pos")
        })
    }

    pageRender()
    {
        let data: any[] = [];

        let sites = this.state.size / this.itemsPerPage;

        for (let i = Math.max(0, this.state.curr - 5); i < Math.min(sites, this.state.curr + 5); i++)
        {
            if (i === this.state.curr)
                data.push(<div key={i+1} data-pos={i} className="active" onClick={this.select} >{i+1}</div>)
            else
                data.push(<div key={i+1} data-pos={i} onClick={this.select} >{i+1}</div>)
        }
        return (
            <React.Fragment>
                <h2>Wyszukiwarka</h2>
                <div className="form">
                    <div>
                        <label htmlFor="nr">Numer zlecenia</label>
                        <input ref={this.inputNrRef} type="text" name="nr" id="nr"/>
                    </div>
                    <div>
                        <label htmlFor="fullname">Imię Nazwisko / Firma</label>
                        <input ref={this.inputNameRef} type="text" name="fullname" id="fullname"/>
                    </div>
                    <div>
                        <input type="button" value="Szukaj" onClick={this.searchClick}/>
                    </div>
                </div>
                <p>
                    {
                        this.state.msg
                    }
                </p>
                <div className="order-list">
                    <div className="header">
                        <div>Nr zlecenia</div>
                        <div>Imię Nazwisko / Firma</div>
                        <div>Urządzenie</div>
                        <div>Akcie</div>
                    </div>
                    {
                        this.state.items.map(e => {
                            return <OrderNewItem key={e.id} data={e} onDelete={this.deleteItem} />
                        })
                    }
                </div>
                <div className="controls">
                    <div onClick={this.left} >&#x22B2;</div>
                    {
                        data.map( e => {
                            return e
                        })
                    }
                    <div onClick={this.right}>&#x22B3;</div>
                </div>
            </React.Fragment>
        )
    }
}