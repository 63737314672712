import React from 'react';
import "./LoginPage.scss";

import UIPage from "./UIPage";

import { history, login } from '../Utils';

interface ILoginPageState
{
    username: string;
    password: string;
    status: any;
}

export default class LoginPage extends UIPage<{}, ILoginPageState>
{
    name = "Login";

    constructor(props: any)
    {
        super(props);

        this.state = {
            username: "",
            password: "",
            status: {}
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void>
    {
        e.preventDefault();
        this.setState({status: ""});

        login(this.state.username, this.state.password).then( e => {
            this.setState({status: e});

            if (e.status === "success") history.push("/");
        });
    }

    pageRender()
    {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <h2>Logowanie</h2>
                    <p>{this.state.status.msg ?this.state.status.msg : ""}</p>
                    <div>
                        <input
                        required
                        type="text"
                        id="login"
                        name="login"
                        placeholder="Login"
                        value={this.state.username}
                        onChange={({target}) => this.setState({username: target.value})}/>
                    </div>
                    <div>
                        <input
                        required
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Hasło"
                        value={this.state.password}
                        onChange={({target}) => this.setState({password: target.value})}/>
                    </div>
                    <div>
                        <button type="submit">Zaloguj</button>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}