import React from 'react';
import Page from "./Page";
import $ from "jquery";

import "./PrintPage.scss"
import { OrderNew, getSessID, Service } from '../Utils';
import { RouteComponentProps } from 'react-router-dom';

interface IPrintItemProp
{
    item?: OrderNew;
    service?: Service;
    simple: boolean;
}

class PrintItem extends React.Component<IPrintItemProp>
{
    render()
    {
        let fragment;

        let date = new Date();

        let out = date.getFullYear() +"";

        out += "-";
        if (date.getMonth()+1 < 10)
            out += "0" + (date.getMonth()+1)
        else
            out += (date.getMonth()+1);

        out += "-";
        if (date.getDate() < 10)
            out += "0" + date.getDate()
        else
            out += date.getDate();

        out += " ";
        if (date.getHours() < 10)
            out += "0" + date.getHours()
        else
            out += date.getHours();

        out += ":";
        if (date.getMinutes() < 10)
            out += "0" + date.getMinutes()
        else
            out += date.getMinutes();

         out += ":";
        if (date.getSeconds() < 10)
            out += "0" + date.getSeconds()
        else
            out += date.getSeconds();

        let fragmentOdbior = (
            <div>
                <b><div>{out}</div></b>
                <div>data wystawienia</div>
            </div>
        );

        if (!this.props.simple)
        {
            fragment = (
                <React.Fragment>
                    <div>
                        <b>Materiały: </b>
                        <div>{this.props.item?.materials?.split("\n").map( e =>
                                {
                                    return (
                                        <React.Fragment>
                                            {e}<br/>
                                        </React.Fragment>
                                        )
                                })}</div>
                    </div>
                    <div>
                        <b>Naprawa: </b>
                        <div>{this.props.item?.labor?.split("\n").map( e =>
                                {
                                    return (
                                        <React.Fragment>
                                            {e}<br/>
                                        </React.Fragment>
                                        )
                                })}</div>
                    </div>
                    <div>
                        <b>Uwagi naprawy: </b>
                        <div>{this.props.item?.notes?.split("\n").map( e =>
                                {
                                    return (
                                        <React.Fragment>
                                            {e}<br/>
                                        </React.Fragment>
                                        )
                                })}</div>
                    </div>
                    <div>
                        <b>Koszt razem: </b>
                        <div>{this.props.item?.cost} zł</div>
                    </div>
                </React.Fragment>
            )

            fragmentOdbior = (
                <div>
                    <b><div>{this.props.item?.completed}</div></b>
                    <div>data wystawienia</div>
                </div>
            )
        }




        return (
                <div className={"Page" + (this.props.simple ? " simple" : "")}>
                    <div>
                        <h2>Zlecenie nr:</h2>
                        <h2>{this.props.item?.nr}</h2>
                    </div>
                    <div>
                        <b><u>Zlecający</u></b>
                        <div>{this.props.item?.fullname}</div>
                        <div>{this.props.item?.address}</div>
                        <div>{this.props.item?.post}</div>
                        <div>{this.props.item?.tel}</div>
                    </div>
                    <div>
                        <img src="/logo.png" alt="logo"/>
                        <b><div>{this.props.service?.name}</div></b>
                        <div>{this.props.service?.post}, {this.props.service?.address}</div>
                        <div>tel. {this.props.service?.tel}</div>
                    </div>
                    <div>
                        <div>
                            <b>Urządzenie: </b>
                            <div>{this.props.item?.item}</div>
                        </div>
                        <div>
                            <b>Zlecenie: </b>
                            <div>{this.props.item?.remarks?.split("\n").map( e =>
                                {
                                    return (
                                        <React.Fragment>
                                            {e}<br/>
                                        </React.Fragment>
                                        )
                                })}</div>
                        </div>
                        {
                            fragment
                        }
                    </div>
                    <div>
                        <p>"właściciel oświadcza, że odbierze oddaną do naprawy rzecz w terminie 3 miesięcy, bez odrębnego wezwania. W przypadku nie odebrania rzeczy gwarant jest upoważniony do sprzedaży rzeczy za cenę i na warunkach według swojego uznania i zaliczenia uzyskanych środków na pokrycie kosztów dokonanej naprawy."</p>
                        <p>Podpis ....................................................................</p>
                    </div>
                    <div>
                        <div><b>{this.props.item?.created}</b></div>
                        <div>data zlecenia</div>
                    </div>
                    <div>
                        <div><b>{this.props.item?.serviceman}</b></div>
                        <div>serwisant</div>
                    </div>
                    <div>
                        <div><b></b></div>
                        <div>popdpis odbiorcy</div>
                    </div>
                    {
                        fragmentOdbior
                    }
                </div>
        )
    }
}

interface IPrintPageState
{
    item?: OrderNew;
    service?: any;
}

async function getOrder(id: number): Promise<OrderNew>
{
    let d = await $.post("/api/orderOne", {id: id, sessid: getSessID()});
    let data = JSON.parse(d);
    
    return data.data;
}

async function getService(): Promise<Service>
{
    let d = await $.post("/api/service", {sessid: getSessID()});
    let data = JSON.parse(d);
    return data.data;
}

enum PrintPageMode
{
    GET = 0,
    OUT = 1
}

export default class PrintPage extends Page<RouteComponentProps<{id: any, mode?: any}>, IPrintPageState>
{
    name = "Print";
    constructor(props: any)
    {
        super(props);
        this.state = {
            item: undefined
        }

        this.print = this.print.bind(this);
    }

    componentDidMount()
    {
        getOrder(this.props.match.params.id).then( e => {
            this.setState({item: e});
        });

        getService().then( e => {
            this.setState({service: e})
        })
    }

    print()
    {
        window.print()
    }

    pageRender()
    {
        let mode = PrintPageMode.OUT;
        if(this.props.match.params.mode)
        {
            mode = Number.parseInt(this.props.match.params.mode)
        }

        if (mode === PrintPageMode.GET)
        {
            return (
                <React.Fragment>
                    <div className="print-div"><div className="print-btn" onClick={this.print}>[Drukuj]</div></div>
                    <PrintItem item={this.state.item} simple={true} service={this.state.service}/>
                    <PrintItem item={this.state.item} simple={true} service={this.state.service}/>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                    <div className="print-div"><div className="print-btn" onClick={this.print}>[Drukuj]</div></div>
                    <PrintItem item={this.state.item} simple={false} service={this.state.service}/>
                </React.Fragment>
            )
        }
    }
}