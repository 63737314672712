import React from 'react';

import "./Footer.scss"

const monthNames = [
    "Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"
]

class Footer extends React.Component
{
    render()
    {
        let date = new Date();

        let format_date = "";

        format_date += monthNames[date.getMonth()];
        format_date += " " + date.getDate();
        format_date += ", " + date.getFullYear();
        format_date += ", " + date.getHours();
        format_date += ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
        format_date += ":" + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());

        return (
            <footer className="App-footer">
                {format_date}
            </footer>
        )
    }
}

export default Footer;
