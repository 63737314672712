import React from 'react';

import UIPage from "./UIPage";

import { history, logout } from '../Utils';

export default class LogoutPage extends UIPage<{}, {msg: string}>
{
    name = "Logout";

    constructor(props: any)
    {
        super(props);
        this.state = {
            msg: ""
        }
    }

    componentDidMount()
    {
        logout().then(msg => {
            this.setState({msg: msg.msg})
            history.push("/");
        });
    }


    pageRender()
    {
        return (
            <React.Fragment>
                <h2>{this.state.msg}</h2>
            </React.Fragment>
        )
    }
}