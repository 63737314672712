import React from 'react';
import $ from "jquery";
import UIPage from "./UIPage";
import { OrderNew, getSessID } from '../Utils';
import OrderNewItem from '../components/OrderNewItem';

import "./UtilsPage.scss"

async function getCompletedOrders()
{
    let d = await $.post("/api/orderTop", {status: 1, sessid: getSessID()});
    let data = JSON.parse(d);   
    return data;
}

async function getNotCompletedOrders()
{
    let d = await $.post("/api/orderTop", {status: 0, sessid: getSessID()});
    let data = JSON.parse(d);
    return data;
}


interface IUtilsPageState
{
    notcompleted: OrderNew[];
    notcompletedmsg: string;
    completed: OrderNew[];
    completedmsg: string;
    msg: string
}
export default class UtilsPage extends UIPage<{}, IUtilsPageState>
{
    name = "Utils";

    constructor(props: any)
    {
        super(props)

        this.state = {
            notcompleted: [],
            notcompletedmsg: "",
            completed: [],
            completedmsg: "",
            msg: ""
        }

        this.deleteItemCompleted = this.deleteItemCompleted.bind(this);
        this.deleteItemNotCompleted = this.deleteItemNotCompleted.bind(this);
    }

    deleteItemCompleted(data: OrderNew)
    {
        getCompletedOrders().then(e =>
            {
                if(e.status === "success")
                    this.setState({completed: e.data})
                else
                    this.setState({completedmsg: e.msg})
            });
    }

    deleteItemNotCompleted(data: OrderNew)
    {
        getNotCompletedOrders().then(e =>
            {
                if(e.status === "success")
                    this.setState({notcompleted: e.data})
                else
                    this.setState({notcompletedmsg: e.msg})
            });
    }

    componentDidMount()
    {
        getCompletedOrders().then(e =>
            {
                if(e.status === "success")
                    this.setState({completed: e.data})
                else
                    this.setState({completedmsg: e.msg})
            });

        getNotCompletedOrders().then(e =>
            {
                if(e.status === "success")
                    this.setState({notcompleted: e.data})
                else
                    this.setState({notcompletedmsg: e.msg})
            });
    }
    
    pageRender()
    {
        return (
            <React.Fragment>
                <h2>Najnowsze nie wykonane</h2>
                <div className="order-list">
                    <p>
                        {
                            this.state.completedmsg
                        }
                    </p>
                    <div className="header">
                        <div>Nr zlecenia</div>
                        <div>Imię Nazwisko / Firma</div>
                        <div>Urządzenie</div>
                        <div>Akcie</div>
                    </div>
                    {
                        this.state.notcompleted.map(e => {
                            return <OrderNewItem key={e.id} data={e} onDelete={this.deleteItemNotCompleted} />
                        })
                    }
                </div>
                <h2>Najnowsze wykonane</h2>
                <div className="order-list">
                    <p>
                        {
                            this.state.completedmsg
                        }
                    </p>
                    <div className="header">
                        <div>Nr zlecenia</div>
                        <div>Imię Nazwisko / Firma</div>
                        <div>Urządzenie</div>
                        <div>Akcie</div>
                    </div>
                    {
                        this.state.completed.map(e => {
                            return <OrderNewItem key={e.id} data={e} onDelete={this.deleteItemCompleted} />
                        })
                    }
                </div>
            </React.Fragment>
        )
    }
}