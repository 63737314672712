import $ from "jquery";
import { History, createBrowserHistory } from 'history';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'

export const createRootReducer = (history: History<unknown>) => combineReducers({
    router: connectRouter(history)
})

export const history = createBrowserHistory();
export function configureStore(preloadedState?: any) {
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history)
            )
        )
    )
    return store;
}

export function isLogged()
{
    let jsess = localStorage.getItem("user");

    if (jsess)
        return true;
    else
        return false;
}

export async function isLoggedAsync()
{
    let sessid = getSessID();

    if(sessid === undefined) return false;

    let a = await $.post("/api/status", {sessid: getSessID()});

    a = JSON.parse(a);

    if(a.status === "error")
    {
        localStorage.clear();
        return false;
    }
    else
    {
        localStorage.setItem("user", JSON.stringify(a.data));
        return true;
    }
}

export async function login(login: string, password: string)
{
    let dd = await $.post("/api/login", {username: login, password: password});
    
    let dj = JSON.parse(dd);

    if (dj.status === "success")
    {
        localStorage.setItem("user", JSON.stringify(dj.data));
    }

    return dj;
}

export async function logout(): Promise<{status: string, msg: string}>
{
    let jsess = localStorage.getItem("user");

    if (jsess)
    {
        let sess = JSON.parse(jsess);

        let dd = await $.post("/api/logout", {sessid: sess.id});

        localStorage.clear();

        return dd;
        
    }

    return {status: "error", msg: "Brak zalogowanego użytkownika"};

}

export function getSessID(): string | undefined
{
    // localStorage.clear();
    let jsess = localStorage.getItem("user");
    
    if (jsess)
    {
        let sess = JSON.parse(jsess);
        
        return sess.id;
    }

    return undefined;
}

export class OrderNew
{
    id?: number;
    nr?: string;
    created?: string;
    fullname?: string;
    remarks?: string;
    address?: string;
    post?: string;
    item?: number;
    serial?: string;
    tel?: string;
    status?: number;
    service?: number;
    notes?: string;
    materials?: string;
    labor?: string;
    cost?: number;
    completed?: string;
    serviceman?: number;

}

export class OrderOld
{
    id?: string;
    nrzlecenia?: string;
    data?: string;
    imie?: string;
    nazwisko?: string;
    uwagi?: string;
    kodpocztowy?: string;
    poczta?: string;
    towar?: string;
    nrseryjny?: string;
    telefon?: string;
    stan?: string;
    miasto?: string;
    uwagi_naprawy?: string;
    materialy?: string;
    robocizna?: string;
    koszt?: string;
    data_wykonania?: string;
    serwisant?: string;
}

export class Service
{
    id?: number;
    name?: number;
    address?: number;
    post?: number;
    tel?: number;
}