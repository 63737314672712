import React from 'react';

import UIPage from "./UIPage";

import "./HomePage.scss";

export default class HomePage extends UIPage
{
    name = "Home";

    pageRender()
    {
        return (
            <React.Fragment>
                <b>Aby mieć dostępdo funkcji, proszę się zalogować.</b>
            </React.Fragment>
        )
    }
}