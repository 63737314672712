import React from 'react';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import "./UIPage.scss"
import Page from './Page';

export default abstract class UIPage<P = {}, S = {}, SS = any> extends Page<P, S, SS>
{
    abstract name: string;

    abstract pageRender(): React.ReactNode;

    render()
    {
        return (
            <React.Fragment  >
                <Header />
                <Navigation />
                <main className={"App-main "+ this.name}>
                    {this.pageRender()}
                </main>
                <Footer />
            </React.Fragment>
        )
    }
}