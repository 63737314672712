import React, { createRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import OrderPage from './pages/OrderPage';
import SearchPage from './pages/SearchPage';
import UtilsPage from './pages/UtilsPage';
import PrintPage from './pages/PrintPage';

import "./App.scss";
import { configureStore, history, isLoggedAsync } from './Utils';
import ErrorPage from './pages/ErrorPage';

import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'

interface IAppState
{
    isLogged: boolean;
}

class App extends React.Component<{}, IAppState>
{
    router = createRef<Router>();
    store = configureStore();

    constructor(props: any)
    {
        super(props);

        this.state = {isLogged: false};
    }

    componentDidMount()
    {
        isLoggedAsync().then(e =>
            {
                this.setState({isLogged: e })
            })

        history.listen((e) => {
            isLoggedAsync().then(e2 =>
                {
                    this.setState({isLogged: e2 })
                })
        });
    }
    
    render()
    {
        let fragment;

        if (this.state.isLogged)
        {
            fragment = (
                <ConnectedRouter history={history} >
                    <Switch >
                        <Route exact path="/" component={UtilsPage}/>
                        <Route exact path="/logout" component={LogoutPage}/>
                
                        <Route path="/search/:query?" component={SearchPage}/>
                        <Route path="/utils" component={UtilsPage}/>
                
                        <Route path="/order/:mode?/:order?" component={OrderPage}/>
                        <Route path="/print/:id/:mode?" component={PrintPage}/>
                        <Route path="/" component={ErrorPage}/>
                    </Switch>
                </ConnectedRouter>
            )
        }
        else
        {
            fragment = (
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/" component={HomePage}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route path="/" component={ErrorPage}/>
                    </Switch>
                </ConnectedRouter>
            )
        }


        return (
            <div className="App">
                <Provider store ={this.store}>
                    {
                        fragment
                    }
                </Provider>
              
            </div>
          );
    }
}

export default App;
