import React from 'react';

import {NavLink} from "react-router-dom";

import "./Navigation.scss";
import { isLogged } from '../Utils';

class Navigation extends React.Component
{
    render()
    {

        let fragment;

        if (isLogged())
        {
            fragment = (
                <React.Fragment>
                    <NavLink activeClassName="active" exact to="/" >Strona główna</NavLink>
                    <NavLink activeClassName="active" to="/search" >Szukaj</NavLink>
                    <NavLink to="/search/status=0" >Szukaj niewykonane</NavLink>
                    <NavLink to="/search/status=1" >Szukaj wykonane</NavLink>
                    <NavLink activeClassName="active" to="/order" >Zlecenia</NavLink>
                    <NavLink activeClassName="active" to="/logout" >Wyloguj</NavLink>
                </React.Fragment>
            )
        }
        else
        {
            fragment = (
                <React.Fragment>
                    <NavLink activeClassName="active" exact to="/" >Strona główna</NavLink>
                    <NavLink activeClassName="active" to="/login" >Logowanie</NavLink>
                </React.Fragment>
            )
        }



        return (
            <nav className="App-navigation">
                {fragment}
            </nav>
        )
    }
}

export default Navigation;
